import React, { Component } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CRow,
  CForm,
  CInputGroupText,
} from "@coreui/react";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import Versioning from "../../../common/versioning";
import { updateToken } from "../../../helpers/Helpers";
import logo from "../../../assets/img/brand/logo.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

const cookies = new Cookies();

/**
 * Questa vista permetta la visualizzazione della pagina di login per l'accesso
 * al personale autorizzato. <br>
 * <hr>
 *
 * I dati per effettuare il login vengono richiamati dall'helper (Helper.js) <br>
 *
 * @property {object} location Oggetto JSON che contiene l'URL della maschera compresi i parametri
 * <pre><div style="line-height:1.3em">
 * {
 *  "location": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 * @property {object} history Oggetto JSON che contiene la history del browser
 * <pre><div style="line-height:1.3em">
 * {
 *  "history": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 */

class Login extends Component {
  /**
   * @category viste-standard
   * @subcategory pages
   */
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      redirectToReferrer: cookies.get("JEProjectJWTTK") !== undefined,
      result: "",
      usernameErrato: false,
      passwordErrata: false,
    };
    this.updateUsernameValue = this.updateUsernameValue.bind(this);
    this.updatePwdValue = this.updatePwdValue.bind(this);
  }

  /**
   * Metodo handler per la gestione del login
   */
  handleLogin() {
    this.callLogin();
  }

  /**
   * Metodo handler per la gestione del submit del form
   * @param {object} event
   */
  handleSubmit(event) {
    event.preventDefault();
  }

  /**
   * Metodo per la chiamata al servizio di login
   */
  callLogin() {
    const self = this;
    var username = self.state.username;
    var password = self.state.password;
    var durataToken =
      process.env.REACT_APP_NDAYS_COOKIE > 0 ? process.env.REACT_APP_NDAYS_COOKIE : 1;
    let redirectPath = null;
    let encodedRouteParameters = null;
    let pathname = null;
    if (
      this.props.history &&
      this.props.history.state &&
      this.props.history.state.from &&
      this.props.history.state.from.pathname
    ) {
      pathname = this.props.history.state.from.pathname;
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from &&
      this.props.location.state.from.pathname
    ) {
      pathname = this.props.location.state.from.pathname;
    }
    if (pathname != null) {
      let ar = pathname.split(":");
      encodedRouteParameters = ar[1];
      if (ar[0].startsWith("/")) {
        redirectPath = ar[0].substring(1, ar[0].length);
      }
      if (redirectPath.endsWith("/")) {
        redirectPath = redirectPath.substring(0, redirectPath.length - 1);
      }
    }
    updateToken(
      "JEProjectJWTTK",
      durataToken,
      "JEProjectTMP",
      {
        username,
        password,
      },
      self.props.history,
      redirectPath,
      encodedRouteParameters,
      function (error) {
        self.setState({
          result: error,
        });
      }
    );
  }

  /**
   * Metodo per l'aggiornamento dello stato del campo username
   * @param {unknown} evt
   */
  updateUsernameValue(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  /**
   * Metodo per l'aggiornamento dello stato del campo password
   * @param {unknown} evt
   */
  updatePwdValue(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  /**
   * @returns {Login}
   */
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    return (
      <div className='bg-light min-vh-100 d-flex flex-row align-items-center'>
        <CContainer className='loginPage'>
          <CRow className='justify-content-center'>
            <CCol md='6' lg='4'>
              <CCard>
                <CCardBody>
                  <CForm onSubmit={(event) => this.handleSubmit(event)}>
                    <CRow className='justify-content-center'>
                      <CCol xs='8'>
                        <img
                          src={logo}
                          className='img-fluid center-block'
                          style={{ width: "100%" }}
                          alt='Logo Aziendale'
                        />
                      </CCol>
                    </CRow>
                    <p className='text-muted text-center'>Accedi al tuo account</p>
                    <CInputGroup className='mb-2'>
                      <CInputGroupText>
                        <FontAwesomeIcon icon={["fas", "user"]} />
                      </CInputGroupText>

                      <CFormInput
                        type='text'
                        placeholder='Username'
                        value={this.state.username}
                        onChange={this.updateUsernameValue}
                      />
                    </CInputGroup>

                    <CInputGroup className='mb-4'>
                      <CInputGroupText>
                        <FontAwesomeIcon icon={["fas", "lock"]} />
                      </CInputGroupText>
                      <CFormInput
                        type='password'
                        placeholder='Password'
                        onChange={this.updatePwdValue}
                      />
                    </CInputGroup>
                    <CRow className='justify-content-center'>
                      <CCol xs='6' className='d-grid gap-25'>
                        <CButton
                          /*block*/ color='primary'
                          type='submit'
                          className=''
                          onClick={(event) => this.handleLogin(event)}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs='12'>
                        <p className='text-muted text-center'>{this.state.result}</p>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs='12'>
                        <p className='text-muted text-center m-0'>
                          <Versioning className='versioneLogin' />
                        </p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}

export default Login;
